import './App.css';
import { useState, useEffect } from 'react';
import Slider from "react-slick";

import projects from './projects.json';

const sliderSettings = {
  infinite: true,
  arrows: window.innerWidth > 500,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

function App() {
  const [scroll, setScroll] = useState(0);

  function handleScroll() {
    setScroll(window.scrollY);
  };

  function handleUpButton() {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="App">
      <header className="header">
        <h1>Денис Перков</h1>
        <a className='header__button' target='blank' href="https://drive.google.com/file/d/1hcPSuwNDtSievH0Qk6qNLh3Pw6_3OLZb/view?usp=sharing">Резюме</a>
      </header>
      <section className="about-block">
        <p className="about__text-content">
          Фронтенд-разработчик с 3+ годами опыта работы над pet-проектами и фриланс 
          заказами на биржах FL и Upwork. Студент 3-го курса Новосибирского 
          Государственного Технического Университета направления 
          “Прикладная математика и информатика”. Интересуюсь реализацией 
          бизнес-задач с использованием современных технологий.
        </p>
        <ul className="contacts__list">
          <li className="contacts__list-item">
            Телефон: <a className='link' href="tel:+79139566177">+7 (913) 956-61-77</a>
          </li>
          <li className="contacts__list-item">
            Telegram: <a className='link' href="http://t.me/perk_tgd" target="_blank" rel="noopener noreferrer">@perk_tgd</a>
          </li>
          <li className="contacts__list-item">
            GitHub: <a className='link' href="https://github.com/Perk7" target="_blank" rel="noopener noreferrer">github.com/Perk7</a>
          </li>
          <li className="contacts__list-item">
            E-mail: <a className='link' href="mailto:denisperkov0@gmail.com">denisperkov0@gmail.com</a></li>
          <li className="contacts__list-item">Россия, г. Новосибирск (GMT+7)</li>
        </ul>
      </section>
      <section className="projects-block">
        <h2 className="projects-title">Мои проекты</h2>
        {projects.map(proj => (
          <div className="project">
            <h3 className='project__title'>{proj.name}</h3>
            <p className='project__desc'>{proj.description}</p>
            <ul className='project__list'>
              {proj.list && proj.list.map(item => <li className="project__list__item">{item}</li>)}
            </ul>
            <div className="project__images-block">
            <Slider {...sliderSettings}>
              {proj.images.map(item => <img loading="lazy" src={`portfolio/${item}`} className='project-image' alt="" />)}
            </Slider>
            </div>
            <ul className="project__refs">
              <li className="project__link">
                <span className="project__link-label">
                  {({invami: 'APK файл: ', 
                     tgw: 'Исходный код (Frontend): ', 
                     false: 'Сайт: '})[proj.app]}
                </span>
                <a target='blank' href={`https://${proj.refs[0]}`} className="link">{proj.app !== 'invami' ? proj.refs[0] : 'Скачать'}</a>
              </li>
              <li className="project__link">
                <span className="project__link-label">{`Исходный код${proj.app === 'tgw' ? ' (Backend)' : ''}: `}</span>
                <a href={`https://${proj.refs[1]}`} target='blank' className="link">{proj.refs[1]}</a>
              </li>
            </ul>
          </div>
        ))}
      </section>
      <footer className="footer">
        <ul className="footer__links">
          <li className="footer__link">
            <img className='footer__icon' src="icons/telegram.svg" alt="icon" />
            <a className='link footer__ref' href="http://t.me/perk_tgd" target="_blank" rel="noopener noreferrer">Telegram</a>
          </li>
          <li className="footer__link">
            <img className='footer__icon' src="icons/cv.svg" alt="cv" />
            <a className='link footer__ref' target='_blank' href="https://drive.google.com/file/d/1hcPSuwNDtSievH0Qk6qNLh3Pw6_3OLZb/view?usp=sharing" rel="noopener noreferrer">Резюме</a>
          </li>
          <li className="footer__link">
            <img className='footer__icon' src="icons/github.svg" alt="icon" />
            <a className='link footer__ref' href="https://github.com/Perk7" target="_blank" rel="noopener noreferrer">GitHub</a>
          </li>
        </ul>
      </footer>
      {scroll > 500 && <button className='button-top' onClick={handleUpButton}><img src="icons/arrow.svg" alt="To top" /></button>}
    </div>
  );
}

export default App;